import API from "API";
import { action, Action, computed, Computed, persist, State, thunk, Thunk } from "easy-peasy";
import { User, UserLoginModel } from "models/profile";
import { StoreRequestStatus } from "store/types";
import { sleep } from "utils";

export interface AuthStoreModel {
  data?: State<UserLoginModel>;
  token: string | null | undefined;
  tokenHeaderSet: boolean;
  valid: Computed<AuthStoreModel, boolean>;
  user?: State<User>;
  setTokenHeaderSet: Action<AuthStoreModel, boolean>;
  setUser: Action<AuthStoreModel, User>;
  setRequest: Action<AuthStoreModel, StoreRequestStatus>;
  setError: Action<AuthStoreModel, string>;
  logoutSuccess: Action<AuthStoreModel>;
  logout: Thunk<AuthStoreModel>;
  loginSuccess: Action<AuthStoreModel, UserLoginModel>;
  request: StoreRequestStatus;
  error: string | null | undefined;
  login: Thunk<AuthStoreModel, { email: string; passowrd: string }>;
}

const Auth: AuthStoreModel = persist(
  {
    data: undefined,
    token: null,
    tokenHeaderSet: false,
    valid: computed((state) => !!state.token),
    user: undefined,
    request: StoreRequestStatus.IDLE,
    error: null,
    setTokenHeaderSet: action((state, payload) => {
      state.tokenHeaderSet = payload;
    }),
    setRequest: action((state, payload) => {
      state.request = payload;
    }),
    setError: action((state, payload) => {
      state.error = payload;
      state.request = StoreRequestStatus.ERROR;

      throw payload;
    }),

    /**
     * Check session
     */
    checkSession: thunk(async (actions, payload) => {
      // actions.loginSuccess();
    }),

    /**
     * Login actions
     */
    login: thunk(async (actions, payload, helpers) => {
      actions.setRequest(StoreRequestStatus.PENDING);

      try {
        await sleep(250);
        const data = await API.post("login", payload);
        actions.loginSuccess(data);
        actions.setRequest(StoreRequestStatus.SUCCESS);
      } catch (error) {
        console.error("Error while login -> ", error);
        actions.setError(error);
      }
      actions.setRequest(StoreRequestStatus.IDLE);
    }),
    loginSuccess: action((state, payload) => {
      state.data = payload;
      state.token = payload.access_token;
      state.user = payload.user;
    }),

    setUser: action((state, payload) => {
      state.user = payload;
    }),

    /**
     * Logout actions
     */
    logout: thunk(async (actions, payload) => {
      actions.logoutSuccess();
    }),
    logoutSuccess: action((state, payload) => {
      state.data = undefined;
      state.token = null;
      state.tokenHeaderSet = false;
      state.user = undefined;
      localStorage.clear();
    }),
  },
  {
    allow: ["token", "user", "data"],
    storage: "localStorage",
  }
);

export default Auth;
