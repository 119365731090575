import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { createStore, StoreProvider } from "easy-peasy";
import { ConfigProvider } from "antd";
import { QueryClient, QueryClientProvider } from "react-query";

import tr_TR from "antd/es/locale/tr_TR";
import en_GB from "antd/es/locale/en_GB";
import ru_RU from "antd/es/locale/ru_RU";
import i18n from "i18";
import dayjs from "dayjs";
import { Store, StoreConfig } from "store";
import PageLoading from "components/page-loading";

import "./index.less";

const $QueryClient = new QueryClient({ defaultOptions: { queries: { staleTime: 30000, retry: 3, retryDelay: 3000 } } });

const KeyEventHandler = () => {
  React.useEffect(() => {
    const handleTabPress = (e: KeyboardEvent) => {
      let _key = e.keyCode || e.key;

      if (_key && _key === 9) {
        document.body.classList.add("use-keyboard");
      } else {
        document.body.classList.remove("use-keyboard");
      }
    };

    const handleMouseDown = (e: MouseEvent) => document.body.classList.remove("use-keyboard");

    document.addEventListener("keydown", (ev) => handleTabPress(ev));
    document.addEventListener("mousedown", (ev) => handleMouseDown(ev));

    return () => {
      document.removeEventListener("keydown", handleTabPress);
      document.addEventListener("mousedown", handleMouseDown);
    };
  }, []);

  return <span />;
};

/**
 * Language Pack import dynamic
 */
const browserLang = localStorage.getItem("i18nextLng") || navigator.language;
const language = browserLang.indexOf("-") !== -1 ? browserLang.split(/[-_]/)[0] : browserLang;
let languageFull = `${language}_${language.toUpperCase()}`;

if (language === "en") {
  languageFull = `${language}_GB`;
}
const langWithMinus = languageFull.replace("_", "-");

i18n.changeLanguage(langWithMinus);

dayjs.locale(language);
const antLanguage = language === "tr_TR" ? tr_TR : language === "ru_RU" ? ru_RU : en_GB;

i18n.loadLanguages(["tr-TR", "en-EN", "ru-RU"]);

const store = createStore(Store, StoreConfig);

const App = React.lazy(() => import("./App"));

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <React.Suspense fallback={<PageLoading />}>
        <ConfigProvider locale={antLanguage}>
          <QueryClientProvider client={$QueryClient}>
            <App />
          </QueryClientProvider>
        </ConfigProvider>
      </React.Suspense>
    </StoreProvider>
    <KeyEventHandler />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
