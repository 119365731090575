import Axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { sleep } from "utils";
import { GlobalResponseHandler, GlobalErrorHandler } from "./helpers";
import { ICreateAPIModel } from "./models";

let AuthCatcher = async (req: AxiosRequestConfig | Promise<AxiosRequestConfig>) => {
  console.warn("Global Auth Catcher not set yet.");
  await sleep(100);
  return new Promise((resolve, reject) => {
    resolve(true);
  });
};

export function CreateAPI(config: ICreateAPIModel, ResponseHandler = GlobalResponseHandler, ErrorHandler = GlobalErrorHandler) {
  const { url, version } = config;

  const GlobalAuthCatcher = async (request: AxiosRequestConfig | Promise<AxiosRequestConfig>): Promise<AxiosRequestConfig> => {
    await AuthCatcher(request);
    return request;
  };

  let INSTANCE_BASE_URL = url;

  if (version) {
    INSTANCE_BASE_URL += version + "/";
  }

  const API = Axios.create({
    baseURL: INSTANCE_BASE_URL,
  });

  API.interceptors.request.use((req): AxiosRequestConfig | Promise<AxiosRequestConfig> => GlobalAuthCatcher(req));
  API.interceptors.response.use(ResponseHandler, ErrorHandler);

  return API;
}

export const SetAuthCatcher = async (fn: any): Promise<{}> => {
  return new Promise(async (resolve, reject) => {
    AuthCatcher = fn;
    console.info("Auth catcher setted..");
    resolve(true);
  });
};

export const RemoveAuth = async (instance: AxiosInstance) => {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      return resolve(
        instance.interceptors.request.use((request) => {
          delete request.headers["Autharization"];
          console.log("INTERCEPTOR REQUEST HEADERS -> ", request.headers);
          localStorage.clear();
          sessionStorage.clear();
          return request;
        })
      );
    }, 600);
  });
};

const BackendAPI = CreateAPI({ url: "http://ludo.sanalx.net:30000" });
const API = CreateAPI({ url: "http://ludo.sanalx.net:30000" });

export { BackendAPI };
export default API;
