import React from "react";

const PageLoading = () => {
  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "100vh",
        left: 0,
        top: 0,
        background: "#fafafa",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <div style={{ maxWidth: "400px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <img src='/beraaber_logo-01.png' alt='Beraaber' style={{ maxWidth: "100%", width: "100%", height: "auto" }} />
      </div>
    </div>
  );
};

export default PageLoading;
