//@ts-nocheck
import { RemoveAuth } from "API";
import { AxiosResponse, AxiosError } from "axios";

declare module "axios" {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

export interface IGlobalServerErrorResponse {
  result: {
    resultCode: number;
    resultMessage: string;
  };
  returnObject: T;
}

export interface IGlobalServerResponse {
  result: {
    resultCode: number;
    resultMessage: string;
  };
  returnObject: T;
}

export const GlobalResponseHandler = (response: AxiosResponse<IGlobalServerResponse>) => {
  const { returnObject, result } = response.data;

  if (result.resultCode !== 0) {
    throw result.resultMessage;
  }

  return returnObject;
};

export const GlobalErrorHandler = async (responseError: AxiosError<IGlobalServerErrorResponse>) => {
  console.error("Global API error handler->", responseError.response?.data);
  console.error("Global API error handler->", responseError.response?.config.url);
  let err = null;

  console.log("Error ->", responseError.response?.status);

  const status = responseError.response?.status;

  if (responseError.response?.status === 500) {
    throw responseError.response?.data;
  }

  if (status === 401) {
    localStorage.clear();
    window.location.href = "/";
  }

  if (responseError.response && responseError.response.data) {
    const { data, config } = responseError.response;

    if (data?.result?.resultMessage) {
      err = data.result.resultMessage;
    }

    console.error(err);
    throw err;
  }

  err = "Sunucuya erişilemedi.";

  console.error(err);
  throw err;
};
