import { createTypedHooks, EasyPeasyConfig } from "easy-peasy";
import App, { AppStoreModel } from "./app";
import Auth, { AuthStoreModel } from "./auth";

interface StoreModel {
  App: AppStoreModel<{ loading?: boolean | string }>;
  Auth: AuthStoreModel;
}

const { useStoreActions, useStoreState, useStoreDispatch, useStore } = createTypedHooks<StoreModel>();

export { useStoreActions, useStoreState, useStoreDispatch, useStore };

export const Store: StoreModel = {
  App: App,
  Auth: Auth,
};

export const StoreConfig: EasyPeasyConfig = {
  name: "beraaber",
  devTools: process.env.NODE_ENV === "development",
};
